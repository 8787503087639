import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private tokenKey = 'access_token';

  setKey(key: string, token: string): void {
    localStorage.setItem(key, token);
  }

  getKey(key: string): string | null {
    return localStorage.getItem(key);
  }


  removeKey(key: string): void {
    localStorage.removeItem(key);
  }


  setSessionKey(key: string, token: string): void {
    sessionStorage.setItem(key, token);
  }

  getSessionKey(key: string): string | null {
    return sessionStorage.getItem(key);
  }


  removeSessionKey(key: string): void {
    sessionStorage.removeItem(key);
  }

  clearAll(): void {
    localStorage.clear();
  }

  isAuthenticated(): boolean {
    const token = this.getKey(this.tokenKey);
    return !!token;
  }

  getUser(): any {
    const isActiveSwitchUser = !!this.getSwitchedUserDetails();
    let user_details
    if (isActiveSwitchUser) {
      const switchUserDetails = this.getSwitchedUserDetails()
      user_details = switchUserDetails?.user_details;
    } else {
      user_details = JSON.parse(this.getKey('user_details'));
    }
    return user_details;
    //return JSON.parse(localStorage.getItem('user_details'));
  }

  setUserMailDetails(mailDetails) {
    localStorage.setItem('currentUserMailDetails', JSON.stringify(mailDetails))
  }

  getUserMailDetails() {
    return JSON.parse(localStorage.getItem('currentUserMailDetails'))
  }

  setOptionType(type) {
    localStorage.setItem('czOptionType', JSON.stringify(type))
  }

  getOptionType() {
    return JSON.parse(localStorage.getItem('czOptionType'));
  }
  getSwitchedUserDetails() {
    return JSON.parse(localStorage.getItem('switchedUserDetails'));
  }
  setSwitchedUserDetails(data?: any) {
    localStorage.setItem('switchedUserDetails', JSON.stringify(data));
  }
  removeSwitchedUserDetails(data?: any) {
    localStorage.removeItem('switchedUserDetails');
  }

  isMMR(): boolean {
    const userDetails = JSON.parse(this.getKey('user_details'));
    return userDetails?.jobRoleId == 29;
  }

  removeOptionType() {
    this.removeKey('czOptionType');
  }
}
