import { inhouseCountryApi } from './../../constants/country-state-apis';
import { Injectable } from '@angular/core';
import { HttpClientService } from '../httpClient/httpClient.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PaginationType } from '../../interfaces/pagination/pagination-type';
import { permissionEntity } from '../../constants/permission';
import { PermissionsPipe } from '../../pipe/permissions.pipe';
import { specificUserParams, userParams } from '../../helpers/helperfunc';

@Injectable({
  providedIn: 'root',
})
export class CountryStateService {
  permissions_entity = permissionEntity;
  
  constructor(
    private httpClientService: HttpClientService,
    private _permissionPipe: PermissionsPipe // dont remove this
  ) { }

  getCountries(): Observable<any> {
    return this.httpClientService.get(`${inhouseCountryApi.getCountryList}?skip=0&limit=500`);
  }

  getCountriesForModule(pagination: PaginationType, filters?: any): Observable<any> {
    let query = '?';

    if (filters) {
      query += filters;
    }
    query += userParams.call(this, this.permissions_entity.modules.country);
    query += `skip=${pagination.skip}&limit=${pagination.limit}`;
    return this.httpClientService.get(`${inhouseCountryApi.getCountryList}${query}`);
  }
  
  addCountry(payload: any): Observable<any> {
    return this.httpClientService.post(`${inhouseCountryApi.addCountry}`, payload);
  }

  updateCountry(id: string, payload: any): Observable<any> {
    return this.httpClientService.put(`${inhouseCountryApi.updateCountry}${id}`, payload);
  }

  deleteCountry(id: any): Observable<any> {
    return this.httpClientService.delete(`${inhouseCountryApi.deleteCountry}${id}`);
  }

  getStates(): Observable<any> {
    return this.httpClientService.get(`${inhouseCountryApi.getStateList}?skip=0&limit=500`);
  }
  getStatesHigherLimit(): Observable<any> {
    return this.httpClientService.get(`${inhouseCountryApi.getStateList}?skip=0&limit=5000`);
  }


  getStatesForModule(pagination: PaginationType, search?: string, country_id?: any): Observable<any> {
    let query = '?';
    if (country_id) {
      query += `countryId=${country_id}&`
    }
    if (search) {
      query += `search=${search}&`;
    }
    query += userParams.call(this, this.permissions_entity.modules.state);
    query += `skip=${pagination.skip}&limit=${pagination.limit}`;
    return this.httpClientService.get(`${inhouseCountryApi.getStateList}${query}`);
  }

  addState(payload: any): Observable<any> {
    return this.httpClientService.post(`${inhouseCountryApi.addState}`, payload);
  }

  updateState(id: any, payload: any): Observable<any> {
    return this.httpClientService.put(`${inhouseCountryApi.updateState}${id}`, payload);
  }

  deleteState(id: any): Observable<any> {
    return this.httpClientService.delete(`${inhouseCountryApi.deleteState}${id}`);
  }

  getStateByCountry(c_id: any): Observable<any> {
    return this.httpClientService.get(`${inhouseCountryApi.getStateByCountry}${c_id}&skip=0&limit=500`);
  }

  getUniversityCountries(): Observable<any> {
    return this.httpClientService.get(`${inhouseCountryApi.getUniversityCountries}?skip=0&limit=200`);
  }

  getCountriesTerritory(): Observable<any> {
    return this.httpClientService.get(`${inhouseCountryApi.getCountriesTerritory}`);
  }
  getCountryById(id: string): Observable<any> {
    return this.httpClientService.get(`${inhouseCountryApi.getCountryById}${id}`);
  }

  getCountriesById(id: string): Observable<any> {
    return this.httpClientService.get(`${inhouseCountryApi.getCountriesById}${id}`);
  }

  // Regilious
  addReligious(payload: any): Observable<any> {
    return this.httpClientService.post(`${inhouseCountryApi.addReligious}`, payload);
  }

  getReligious(pagination: PaginationType, search?: string, country_id?: any): Observable<any> {
    let query = '?';
    if (country_id) {
      query += `countryId=${country_id}&`
    }
    if (search) {
      query += `search=${search}&`;
    }
    query += userParams.call(this, this.permissions_entity.modules.state);
    query += `skip=${pagination.skip}&limit=${pagination.limit}`;
    return this.httpClientService.get(`${inhouseCountryApi.getReligiousList}${query}`);
  }

  updateReligious(Id: string, payload: unknown): Observable<any> {
    return this.httpClientService.put(inhouseCountryApi.updateReligious.replace(':courseID', Id), payload);
  }

  getReligiousById(id: string): Observable<any> {
    return this.httpClientService.get(inhouseCountryApi.getAutomationById.replace(':courseID', id));
  }

  getStatesForFilters(pagination: PaginationType, search?: string, country_id?: any): Observable<any> {
    let query = '?';
    // if (country_id) {
    //   query += `countryId=${country_id}&`
    // }
    if (search) {
      query += `${search}`;
    }
    query += userParams.call(this, this.permissions_entity.modules.state);
    query += `skip=${pagination.skip}&limit=${pagination.limit}`;
    return this.httpClientService.get(`${inhouseCountryApi.getStateList}${query}`);
  }

}

