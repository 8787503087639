import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { PermissionsPipe } from './core/pipe/permissions.pipe';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { DocViewersComponent } from './views/components/doc-viewers/doc-viewers.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxSummernoteModule } from 'ngx-summernote';
import { DecodeURIComponentPipe } from './core/pipe/decode-uricomponent.pipe';
import { TitleService } from './core/services/title.service';
import { TokenReplaceInterceptor } from './core/interceptors/token-replace.interceptor';
import { RouteReuseStrategy } from '@angular/router';
import { DynamicRouteReuseStrategy } from './core/helpers/single-component-route-reuse-strategy';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent, DocViewersComponent, DecodeURIComponentPipe],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CarouselModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        ToastrModule.forRoot({ preventDuplicates: true }),
        NgxDocViewerModule,
        NgxSummernoteModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: DuplicateRequestInterceptor,
        //     multi: true
        // },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenReplaceInterceptor,
            multi: true
        },
        DatePipe,
        PermissionsPipe,
        TitleService,
        { provide: RouteReuseStrategy, useClass: DynamicRouteReuseStrategy }
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule { }
