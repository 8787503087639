import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomizerSettingsService } from '../app/components/customizer-settings/customizer-settings.service';
import { ToggleService } from '../app/components/common/header/toggle.service';
import { TitleService } from './core/services/title.service';
import { NetworkStatusService } from './core/services/network/network-status.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'Tagus - Material Design Angular Admin Dashboard Template';
    isToggled = false;

    isOnline: boolean = true;
    isRetrying: boolean = false;
    private subscription: Subscription = new Subscription();

    constructor(
        public router: Router,
        private toggleService: ToggleService,
        public themeService: CustomizerSettingsService,
        private titleService: TitleService,
        private networkStatus: NetworkStatusService
    ) {
        this.isOnline = this.networkStatus.isOnline();
        this.subscription = this.networkStatus.online$.subscribe(
            status => {
                this.isOnline = status;
                if (status) {
                    this.isRetrying = false;
                }
            }
        );

        this.toggleService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });
    }
    ngOnInit(): void {
        this.titleService.resetTitle();
    }

    toggleRightSidebarTheme() {
        this.themeService.toggleRightSidebarTheme();
    }

    toggleHideSidebarTheme() {
        this.themeService.toggleHideSidebarTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }
}
