import { Injectable } from '@angular/core';
import { HttpClientService } from '../httpClient/httpClient.service';
import { authenticationApis } from '../../constants/authentication-apis';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from '../localStorage/localStorage.service';
import { sessionExpiryMinutes } from '../../constants/permission';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    profile_pic_update$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private httpClientService: HttpClientService,
        private localStorageService: LocalStorageService) { }

    login(payload: unknown): Observable<any> {
        return this.httpClientService.post(
            authenticationApis.login,
            payload,
            true
        );
    }

    forgotPassowrd(payload: unknown): Observable<any> {
        return this.httpClientService.post(
            authenticationApis.forgotPassword,
            payload,
            true
        );
    }

    resetPassword(payload: unknown): Observable<any> {
        return this.httpClientService.getResetPasswordAuth(
            authenticationApis.resetPassword,
            payload,
        );
    }

    checkPassword(payload: unknown): Observable<any> {
        return this.httpClientService.getResetPasswordAuth(
            authenticationApis.checkPassword,
            payload,
        );
    }

    decodeResetToken(token: string): Observable<any> {
        const query = `${authenticationApis.decodeForgotPasswordToken}?code=${token}`;
        return this.httpClientService.get(query, true);
    }

    scenner(): Observable<any> {
        return this.httpClientService.get(authenticationApis.getScanner);
    }

    getPermissions() {
        const switchedUserDetails = this.localStorageService.getSwitchedUserDetails();
        let permissions;
        if (switchedUserDetails) {
            permissions = switchedUserDetails?.roles_permissions
        } else {
            permissions = JSON.parse(this.localStorageService.getKey('roles_permissions'));
        }

        return permissions[0]?.permission;
    }

    getRoleAssets() {
        let role_assets = JSON.parse(this.localStorageService.getKey('roles_permissions'));
        return role_assets;
    }

    getLoginUserDetails() {
        const isActiveSwitchUser = !!this.localStorageService.getSwitchedUserDetails();
        let user_details
        if (isActiveSwitchUser) {
            const switchUserDetails = this.localStorageService.getSwitchedUserDetails()
            user_details = switchUserDetails?.user_details;
        } else {
            user_details = JSON.parse(this.localStorageService.getKey('user_details'));
        }
        return user_details;
    }

    triggerProfilePicUpdate() {
        return this.profile_pic_update$.asObservable();
    }

    switchLogin(payload): Observable<any> {
        return this.httpClientService.post(
            authenticationApis.switchLogin,
            payload
        );
    }

    sessionExpiryTimeInMiniutes() {
        const userDetails = this.getLoginUserDetails();
        return Number(userDetails?.sessionExpiryTimeInMiniutes || sessionExpiryMinutes);
    }
}
