import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/localStorage/localStorage.service';

@Injectable()
export class TokenReplaceInterceptor implements HttpInterceptor {

  constructor(private _lc: LocalStorageService) { }

  private getReplacementToken(): string {
    const switchedUserDetails = this._lc.getSwitchedUserDetails();
    return switchedUserDetails?.user_details?.token || this._lc.getKey('access_token') || '';
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Skip if bypass header is present or request is not a GET
    if (request.headers.has('X-Bypass-Token-Replacement') || request.method !== 'GET') {
      const modifiedRequest = request.headers.has('X-Bypass-Token-Replacement')
        ? request.clone({ headers: request.headers.delete('X-Bypass-Token-Replacement') })
        : request;
      return next.handle(modifiedRequest);
    }

    const originalAuthorization = request.headers.get('Authorization');
    const replacementToken = this.getReplacementToken();

    // // Preserve specific Basic auth for hardcoded credentials
    // if (originalAuthorization?.includes('Basic ' + btoa('akorn:akorn')) || 
    //     originalAuthorization?.includes('Basic ' + btoa('crizac:crizac'))) {
    //   return next.handle(request);
    // }

    // Handle token replacement
    let modifiedRequest: HttpRequest<unknown>;

    if (originalAuthorization?.startsWith('Bearer')) {
      modifiedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${replacementToken}`
        }
      });
    } 
    else if (originalAuthorization?.startsWith('Basic')) {
      const basicPrefix = originalAuthorization.split(' ')[0];
      modifiedRequest = request.clone({
        setHeaders: {
          Authorization: `${basicPrefix} ${replacementToken}`
        }
      });
    } 
    else {
      modifiedRequest = request.clone({
        setHeaders: {
          Authorization: replacementToken
        }
      });
    }

    return next.handle(modifiedRequest);
  }
}